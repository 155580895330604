import React, { useEffect } from "react";
import { css } from "styled-components";

import { OG_IMAGE } from "../../../utils/constants";
import { clearSwagStoreCart } from "../storeHelper";
import { STORE_PAGE_URL } from "../../../utils/constants";
import { Flex, Image, Heading, Text } from "../../../globals/UIKit";
import Layout from "../Layout";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import { BackButton } from "../ProductDetails/BackButton";
import CopyWriterWithSidebar from "../../common/CopyWriterWithSidebar";
import Seo from "../../seo";

const canonicalUrl = "https://store.hasura.io/order/success/";

export const OrderSuccess = (props) => {
  useEffect(() => {
    clearSwagStoreCart();
  }, []);

  return (
    <Layout location={props.location}>
      <StyledContainerWrapper>
        <div>
          <Seo
            title="Order Successful | Hasura Swag Store "
            description="Our favourite Hasura designs now in merch you can get"
            meta={OG_IMAGE}
            canonicalLink={canonicalUrl}
          />
          <Flex
            flexDirection="column"
            width="100%"
            minHeight="calc(100vh - 165px)"
            padding="48px 0"
            css={css`
              text-align: center;

              @media (max-width: 720px) {
                padding: 50px 0 0;

                #success-illus {
                  min-width: 200px;
                  width: 160%;
                  margin-left: -10%;
                }

                h1 {
                  font-size: 28px;
                  line-height: 1.5;
                }

                p {
                  font-size: 18px;
                  width: 100%;
                }

                a {
                  margin-bottom: 24px;
                }
              }
            `}
          >
            <Flex width="100%">
              <BackButton redirectTo={STORE_PAGE_URL} />
            </Flex>
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1665053704/Swag%20Store/swag_order_status_ywq4xa.jpg"
              alt="swag_success"
              width="68%"
              // maxWidth="357px"
              id="success-illus"
              // minHeight="500px"
            />
            <Heading
              fontSize="36px"
              fontWeight={700}
              color="#23303D"
              lineHeight="1.25"
              mb="12px"
              textAlign="center"
            >
              Yay! Your order's complete!
            </Heading>
            <Text
              fontSize="16px"
              fontWeight={500}
              color="#1B2738"
              lineHeight="1.5"
              textAlign="center"
              width="50%"
              mb="24px"
            >
              We’ve sent you an email of your order. For any shipping related
              queries please reach out to&nbsp;
              <a href="mailto:community@hasura.io">community@hasura.io</a>
            </Text>
            <a href="https://hasura.io/" className="hasura-web-nav-btn">
              <Flex
                as="button"
                justifyContent="center"
                width="161px"
                height="44px"
                borderRadius="8px"
                bg="#23303D"
                color="#fff"
                lineHeight="44px"
                fontSize="16px"
                fontWeight={500}
                border="none"
              >
                Go to Hasura
              </Flex>
            </a>
          </Flex>
          <CopyWriterWithSidebar location={props.location} />
        </div>
      </StyledContainerWrapper>
    </Layout>
  );
};
