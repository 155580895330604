import { isBrowser } from "../../utils/constants";

export const getCartItemsFromLocalStorage = () => {
  if (isBrowser) {
    const existingCartItems = window.sessionStorage.getItem("cartItems");

    if (existingCartItems) {
      const parsedValue = JSON.parse(existingCartItems);

      if (parsedValue && parsedValue.constructor.name === "Array")
        return parsedValue;
    }

    return [];
  }
};

// *************************** //

export const checkIsItemAlreadyAddedToCart = (cartItems, priceId) => {
  if (cartItems && cartItems.length > 0) {
    const currentCartItems = cartItems.filter(
      (cartItem) => cartItem?.price_id === priceId
    );

    if (currentCartItems.length === 1) {
      return true;
    }
  }

  return false;
};

// ********************************* //

export const getTotalNoOfAddedCartItems = () => {
  if (isBrowser) {
    const cartItems = getCartItemsFromLocalStorage();

    if (cartItems && cartItems.length > 0) {
      const totalNoOfItems = cartItems.reduce(
        (acc, cartItem) => acc + cartItem.product_count,
        0
      );

      return totalNoOfItems;
    }

    return 0;
  }
};

// ********************************** //

export const getTotalNoOfAddedCartItem = (priceId) => {
  const cartItems = getCartItemsFromLocalStorage();

  if (cartItems && cartItems.length > 0) {
    const filteredCartItemArr = cartItems
      .filter((cartItem) => cartItem.price_id === priceId)
      .reduce((acc, cartItem) => acc + cartItem.product_count, 0);

    return filteredCartItemArr;
  }

  return 0;
};

// *************************** //

export const removeCartItem = (priceId) => {
  if (isBrowser) {
    const cartItems = getCartItemsFromLocalStorage();

    const isItemAlreadyAddedToCart = checkIsItemAlreadyAddedToCart(
      cartItems,
      priceId
    );

    if (isItemAlreadyAddedToCart) {
      const itemIndex = cartItems.findIndex(
        (cartItem) => cartItem?.price_id === priceId
      );

      const filteredCartItemsArr = cartItems.filter(
        (_cartItem, index) => index !== itemIndex
      );

      return window.sessionStorage.setItem(
        "cartItems",
        JSON.stringify(filteredCartItemsArr)
      );
    }
  }
};

// *************************** //

export const getInitialStateForNoOfItemsToAdd = (priceId) => {
  const noOfItemsAlreadyAdded = getTotalNoOfAddedCartItem(priceId);

  // Case - Nothing is added in cart.
  if (noOfItemsAlreadyAdded === 0) {
    return 1;
  }

  return noOfItemsAlreadyAdded;
};

// *************************** //

// export const generateNewSwagStoreOrderId = () => {
//   const currentTimeStampInSeconds = Math.floor(Date.now() / 1000);

//   return `sw-${currentTimeStampInSeconds}`;
// };

// *************************** //

export const clearSwagStoreCart = () => {
  if (isBrowser) {
    const existingCartItems = window.sessionStorage.getItem("cartItems");

    if (existingCartItems) {
      return window.sessionStorage.removeItem("cartItems");
    }
  }
};

// *************************** //

export const MEN_FIT_SIZE_LINK = `https://www.bellacanvas.com/fit-size-charts#men-tab`;

export const WOMEN_FIT_SIZE_LINK = `https://www.bellacanvas.com/fit-size-charts#women-tab`;

// ******************************** //

export const addAndUpdateItemsInCart = (
  productId,
  productCount,
  sizeId,
  productName,
  productImg,
  productPrice,
  productURL,
  priceId,
  stockAvailable
) => {
  if (isBrowser) {
    const cartItems = getCartItemsFromLocalStorage();

    const isItemAlreadyAddedToCart = checkIsItemAlreadyAddedToCart(
      cartItems,
      priceId
    );

    if (isItemAlreadyAddedToCart) {
      const itemIndex = cartItems.findIndex(
        (cartItem) => cartItem?.price_id === priceId
      );

      cartItems[itemIndex].product_count = productCount;

      return window.sessionStorage.setItem(
        "cartItems",
        JSON.stringify(cartItems)
      );
    }

    // If Items is not added in cart before - Fresh Cart

    const cartItem = {
      product_id: productId,
      product_count: productCount,
      apparel_size_id: sizeId,
      product_name: productName,
      product_img: productImg,
      product_price: productPrice,
      product_url: productURL,
      price_id: priceId,
      stock_available: stockAvailable,
    };

    cartItems.push(cartItem);

    return window.sessionStorage.setItem(
      "cartItems",
      JSON.stringify(cartItems)
    );
  }

  return null;
};

// ******************************** //

export const updateNoOfItemsInCart = (priceId, productCount) => {
  if (isBrowser) {
    const cartItems = getCartItemsFromLocalStorage();

    const isItemAlreadyAddedToCart = checkIsItemAlreadyAddedToCart(
      cartItems,
      priceId
    );

    if (isItemAlreadyAddedToCart) {
      const itemIndex = cartItems.findIndex(
        (cartItem) => cartItem?.price_id === priceId
      );

      cartItems[itemIndex].product_count = productCount;

      return window.sessionStorage.setItem(
        "cartItems",
        JSON.stringify(cartItems)
      );
    }

    return null;
  }

  return null;
};
